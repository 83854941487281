import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { api } from '../../api/api'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'

import './Profile.style.css'
import { MdOutlineDateRange, MdOutlineKeyboardBackspace, MdOutlinePublic } from 'react-icons/md';
import { Modal, Spinner } from 'react-bootstrap';
import { AiOutlineGlobal, AiOutlineDisconnect, AiFillMessage } from 'react-icons/ai'
import { FiTag } from 'react-icons/fi'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { FiMail, FiFileText } from 'react-icons/fi'
import { MdPersonAddAlt1 } from 'react-icons/md'
import { TbEditCircle, TbPlugConnected } from 'react-icons/tb'
import { FaUsers, FaUserTimes } from 'react-icons/fa'
import { color } from '../../hooks/Utils/color';
import Moment from 'react-moment';
import { formatCash } from '../../hooks/Utils/parsing';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture'
import Badges from '../../components/badges/Badges'
import Opinion from '../../components/Opinion/Opinion'
import Header from '../../components/Header/Header'
import Audience from '../../components/Audience/Audience'
import { TfiMenu } from 'react-icons/tfi'
import ModalEditBadges from '../../components/ModalEditBadge/ModalEditBadge'
import ModalEditProfile from '../../components/ModalEditProfile/ModalEditProfile'
import ModalSetting from '../../components/ModalSetting/ModalSetting'
import ic_coins from '../../assets/coins/coins.png'
import ModelAlert from '../../components/ModalAlert/ModelAlert'
import EmptyPage from '../../components/EmptyPage/EmptyPage'
import { Button } from '@mui/material'
import Cover from 'components/Cover/Cover'
import CoverProfilePicture from 'components/CoverProfilePicture/CoverProfilePicture'



const Profile = () => {

    const navigate = useNavigate()
    const { data: authData } = useAuthReducer()
    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const { user_username } = useParams();
    const [userProfile, setUserProfile] = useState(null)
    const [userIsMuted, setUserIsMuted] = useState(false)
    const [user_id, setUser_id] = useState(null)
    const [opinions, setOpinions] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [accountStatus, setAccountStatus] = useState('AVAILABLE')
    const [totalUserLikes, setTotalUserLikes] = useState(0)

    const [showAudience, setShowAudience] = useState(false)
    const [showEditProfile, setShowEditProfile] = useState(false)
    const [showEditBadge, setShowEditBadge] = useState(false)
    const [showSetting, setShowSetting] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    const [userBalance, setUserBalance] = useState(0)


    // NAVIGATION HANDLE
    const goToEditProfile = () => navigate(`/profiles/user/${user_id}/edit`)
    const goToIntranet = () => navigate(`/intranet`)
    const goToSettings = () => navigate(`/profiles/user/${user_id}/settings`, {
        likes: totalUserLikes
    })
    const goToAudience = () => {
        if (authData?.user?.user_id === userProfile?.user_id) {
            navigate(`/profiles/user/${user_id}/network`)
        }
    }
    const goToInbox = () => {
        navigate(`/one-to-one/${userProfile?.user_id}`)
    }

    const goBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        setIsLoading(true)

        const fetchUser = async () => {
            try {
                const request = await api(`api/profile/v2/user-profile-info/${user_username}?page=${1}`, 'GET', null, accessToken);
                const response = await request.json()
                // console.log('Response user profile with user_username: ', response?.user_profile_info[0]?.user_id)
                if (response?.success) {
                    setUser_id(response?.user_profile_info[0]?.user_id)
                    fetchProfile(response?.user_profile_info[0]?.user_id)
                    fetchAudience(response?.user_profile_info[0]?.user_id)
                } else {
                    setIsLoading(false)
                    setIsEmpty(true)
                    setAccountStatus(response?.data?.type)
                }
            } catch (error) {
                fetchUser()
                console.error('Get opinion comments:', error.message);
            }
        }

        fetchUser()
    }, [])


    const fetchProfile = async (user) => {
        try {
            const request = await api(`api/profile/v2/user-profile-info/${user}?page=${1}`, 'GET', null, accessToken);
            const response = await request.json()
            // {userProfile?.friends_accepted?.length !== 0 || userProfile?.friends_invited?.length !== 0 || authData?.user?.user_gold &&
            // console.log('Response user profile: ', response?.user_profile_info[0]?.friends_invited)
            if (response?.success) {
                setIsLoading(false)
                setIsEmpty(false)
                setUserProfile(response?.user_profile_info[0])
                if (response?.opinions?.data.length === 0) {
                    setHasMore(false)
                    return
                }
                setOpinions([...opinions, ...response?.opinions?.data])
                setUserIsMuted(response?.user_profile_info[0]?.muters_friends?.length > 0)
                setTotalUserLikes(response?.soutiens_count)
                setPage(2)
                setHasMore(true)
            } else {
                setIsEmpty(true)
                setAccountStatus(response?.data?.type)
            }
            setIsLoading(false)
        } catch (error) {
            fetchProfile(user)
            console.error('Get opinion comments:', error.message);
        }
    }


    const fetchProfileMoreOpinions = async () => {
        try {
            const request = await api(`api/profile/v2/user-profile-info/${user_id}?page=${page}`, 'GET', null, accessToken);
            const response = await request.json()
            // console.log('Response user profile: ', response)
            if (response.success) {
                // setUserProfile(response?.user_profile_info[0])

                if (response?.opinions?.data.length === 0) {
                    setHasMore(false)
                    return
                }
                setOpinions([...opinions, ...response?.opinions?.data])
                setTotalUserLikes(response?.soutiens_count)
                setPage(page => page + 1)
                setHasMore(true)
            }
            setIsLoading(false)
        } catch (error) {
            fetchProfileMoreOpinions()
            console.error('Get opinion comments:', error.message);
        }
    }


    useEffect(() => {
        const getUserBalance = async () => {
            try {
                // console.log('Fetch transactions...')
                const request = await api(`api/transactions?page=${1}`, 'GET', null, accessToken)
                const response = await request.json()
                // console.log('Response user balance updated:', response?.balance)
                if (request.ok && request.status === 200) {
                    setUserBalance(response?.balance)
                }
            } catch (e) {
                getUserBalance()
                console.warn(e.message);
            }
        }
        getUserBalance()
    }, [])



    /* FETCH USER AUDIENCE */
    const [inviteReceivedCount, setinviteReceivedCount] = useState(0)

    const fetchAudience = async (id) => {
        try {
            if (authData?.user?.user_id === id) {
                const request = await api(`api/user/audience/${authData?.user?.user_id}`, 'GET', null, accessToken)
                const response = await request.json()
                // console.log('Fetch audience response:', response?.audience[0]?.invite_received)
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        if (response?.audience[0]) {
                            setinviteReceivedCount(response?.audience[0]?.invite_received?.length || 0)
                        }
                    }
                }
            }
        } catch ({ message }) {
            fetchAudience()
            throw new Error(message)
        }
    }


    const approveBuddy = async () => {
        const body = { user_id }
        try {
            const request = await api(
                'api/profile/approve-request-buddy',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile(user_id)

                    //   sendNotification(user_id, {
                    //     title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
                    //     body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a accepté votre demande.`,
                    //     url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
                    //   })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }

    const requestBuddy = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/request-buddy',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            // console.log(response, 'response off request buddy')
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile(user_id)

                    //   sendNotification(user_id, {
                    //     title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
                    //     body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} vous a envoyé une demande de pote.`,
                    //     url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
                    //   })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }

    const cancelBuddy = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/request-cancel-buddy',
                'DELETE',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile(user_id)

                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }


    const deleteBuddy = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/delete-buddy',
                'DELETE',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile(user_id)

                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }



    const becomeOrDeleteFan = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/fan-treatment',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            // console.log(response, 'RESPONSE OF BECOME OR DELETE FAN')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    fetchProfile(user_id)

                    //   if (response?.notification) {
                    //     sendNotification(user_id, {
                    //       title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
                    //       body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} est devenu votre fan.`,
                    //       url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
                    //     })
                    //   }

                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }

    const blockBuddy = async () => {
        try {
            const body = { user_id }
            const request = await api(
                'api/profile/blocked',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {

                    fetchProfile(user_id)
                    // navigation.navigate('Audience', {
                    //     user_id: authStateContext?.user?.user_id,
                    //     user_surname: authStateContext?.user?.user_surname,
                    //     user_name: authStateContext?.user?.user_name,
                    //     user_username: authStateContext?.user?.user_username,
                    //     prof_picture: authStateContext?.user?.profile?.prof_picture,
                    //     sourceFrom: 'Profil/block',
                    // })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }


    const updateOpinion = (data) => {
        const new_opinions = opinions.map(opinion =>
            opinion.sond_id !== data.sond_id ? opinion : data,
        )
        setOpinions(new_opinions)
    }

    const deleteOpinion = (data) => {
        setOpinions(opinions.filter(opinion => opinion.sond_id !== data?.sond_id))
    }


    if (isLoading) {
        return (
            <div style={{ width: '100%', backgroundColor: 'white', borderLeft: '1px #f1f1f1 solid', borderRight: '1px #f1f1f1 solid' }}>
                <Header title={t('profile')} goBack={goBack} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '91vh' }}>
                    <div style={{ textAlign: 'center', marginBottom: '50px' }}>
                        <Spinner />
                        <h6 style={{ fontWeight: 'bold', marginTop: '5%', fontSize: '16px' }}>
                            {t('loading_profile')}
                        </h6>
                    </div>
                </div>
            </div>
        )
    }



    if (isEmpty) {
        return (
            <div style={{ backgroundColor: 'white', borderLeft: '1px #f1f1f1 solid', borderRight: '1px #f1f1f1 solid', height: '100vh' }}>
                <Header title={t('profile')} goBack={goBack} />
                <div style={{ height: '220px', backgroundColor: '#F1F1F1' }}>
                </div>
                <div style={{ width: '140px', height: '140px', borderRadius: '140px', marginTop: '-70px', marginLeft: '50px', border: '3px solid white', backgroundColor: '#F1F1F1' }}>
                </div>
                <h4 style={{ marginRight: '50px', marginLeft: '50px', marginTop: '15px' }}>
                    {accountStatus === 'BLOCKED' ? t('account_blocked') :
                        accountStatus === 'SUSPENDED' ? t('account_suspended') :
                            t('account_desactived')
                    }
                </h4>
                <p style={{ fontWeight: '500', color: '#969696', marginRight: '50px', marginLeft: '50px', marginTop: '15px' }}>
                    {accountStatus === 'BLOCKED' ? t('account_blocked_desc') :
                        accountStatus === 'SUSPENDED' ? t('account_suspended_desc') :
                            t('account_desactived_desc')
                    }
                </p>
                <p style={{ fontWeight: '500', color: '#969696', marginRight: '50px', marginLeft: '50px', marginTop: '15px' }}>
                    {t('for_more_information_please_carefully_read_our')}
                    {' '}
                    <a href={t('link_terms_of_use')}>
                        {t('terms_and_conditions')}
                    </a>
                    {' '}
                    {t('and')}
                    {' '}
                    <a href={t('link_privacy_policy')}>
                        {t('privacy_policy')}
                    </a>
                </p>
            </div>
        )
    }



    const renderProfile = () => {
        return (
            <div>
                <div className='content-profile'>

                    <Cover
                        user={userProfile}
                        onRefresh={fetchProfile}
                    />

                    <div className='picture'>

                        <CoverProfilePicture
                            user={userProfile}
                            onRefresh={fetchProfile}
                        />

                        <div className='account'>
                            <h3>{userProfile?.user_surname} {userProfile?.user_name}</h3>
                            <h6 style={{ marginTop: '-5px', color: '#808080' }}>
                                @{userProfile?.user_username}
                            </h6>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Badges size={20} user={userProfile} />
                                {authData?.user?.user_id === user_id &&
                                    <button
                                        onClick={() => setShowEditBadge(true)}
                                        style={{ marginTop: '-3px' }}>
                                        <TbEditCircle size={22} color='#808080' />
                                    </button>
                                }
                            </div>
                        </div>

                    </div>


                    {/* RENDER DESCRIPTION PROFILE */}
                    <div className='description'>

                        {userProfile?.profile?.prof_title &&
                            <div className='box-info'>
                                <FiTag size={22} color="#808080" />
                                <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                    {userProfile?.profile?.prof_title}
                                </span>
                            </div>
                        }

                        {userProfile?.profile?.prof_description &&
                            <div className='box-info'>
                                {/* <FiFileText size={46} style={{width: '75px', height: '75px'}} color="#808080" /> */}
                                <span style={{ fontSize: '14px', color: "#414141" }}>
                                    {userProfile?.profile?.prof_description}
                                </span>
                            </div>
                        }

                        {/* VILLE */}
                        {userProfile?.user_ville &&
                            <div className='box-info'>
                                <HiOutlineLocationMarker size={24} color="#808080" />
                                <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                    {userProfile?.user_ville}
                                </span>
                            </div>
                        }

                        {userProfile?.profile?.prof_email &&
                            <div className='box-info'>
                                <FiMail size={22} color="#808080" />
                                <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                    {userProfile?.profile?.prof_email}
                                </span>
                            </div>
                        }

                        {userProfile?.profile?.prof_site_web &&
                            <div className='box-info'>
                                <AiOutlineGlobal size={22} color="#808080" />
                                <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                    {userProfile?.profile?.prof_site_web}
                                </span>
                            </div>
                        }

                        <div className='box-info'>
                            <MdOutlineDateRange size={22} color="#808080" />
                            <span style={{ fontSize: '14px', color: "#414141", marginLeft: '10px' }}>
                                Inscrit(e) depuis {' '}
                                <Moment format="MMMM YYYY" local={true} locale={'fr'}>
                                    {userProfile?.user_created_at?.sond_publishing}
                                </Moment>
                            </span>
                        </div>

                    </div>

                    {authData?.user?.user_id != user_id &&
                        <div className='button-box'>
                            {renderBuddyButton()}
                            {renderFanButton()}
                            {(userProfile?.friends_accepted?.length !== 0 || userProfile?.friends_invited?.length !== 0 || authData?.user?.user_gold) &&
                                <button
                                    onClick={goToInbox}
                                    className='button'
                                    style={{
                                        width: '80%',
                                        backgroundColor: 'white',
                                        border: `1.8px ${color.secondary} solid`
                                    }}>
                                    <AiFillMessage size={18} style={{ width: '18px', height: '18px', marginRight: '5px', color: color.secondary }} />
                                    <span style={{ fontWeight: 'bold', color: color.primary, fontSize: '13px', }}>
                                        Inbox
                                    </span>
                                </button>
                            }
                        </div>
                    }
                    {authData?.user?.user_id === user_id &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '3%' }}>
                            <button
                                onClick={goToEditProfile}
                                style={{
                                    border: `2px ${color.secondary} solid`,
                                    paddingTop: '3px',
                                    paddingBottom: '3px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    borderRadius: '50px',
                                    textSize: '12px',
                                    color: color.secondary,
                                    fontWeight: '600'
                                }}>
                                {t('edit_profile')}
                            </button>

                            <div
                                onClick={() => navigate(`/profiles/user/${user_id}/transactions`, { state: { likes: totalUserLikes } })}
                                style={{
                                    border: `2px ${color.secondary} solid`,
                                    paddingTop: '3px',
                                    paddingBottom: '3px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    borderRadius: '50px',
                                    textSize: '12px',
                                    color: color.primary,
                                    fontWeight: '600',
                                    cursor: 'pointer'
                                }}>
                                <img src={ic_coins} width="20" alt='' />
                                <span style={{ color: '#808080', marginBottom: '-2px' }}> {userBalance}</span>
                            </div>

                            <div
                                onClick={goToIntranet}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: `2px ${color.secondary} solid`,
                                    paddingTop: '3px',
                                    paddingBottom: '3px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    borderRadius: '50px',
                                    textSize: '12px',
                                    color: color.secondary,
                                    fontWeight: '600',
                                    cursor: 'pointer'
                                }}>
                                <span style={{ marginRight: '5px' }}>Intranet</span>
                                <img
                                    src={require('assets/icons/rh_request_icon.png')}
                                    style={{
                                        width: '15px',
                                        height: '15px',
                                        marginRight: '5px'
                                    }}
                                />

                                <img
                                    src={require('assets/icons/doc_share_icon.png')}
                                    style={{
                                        width: '15px',
                                        height: '15px',
                                        marginRight: '5px'
                                    }}
                                />

                                <img
                                    src={require('assets/icons/calendar_icon.png')}
                                    style={{
                                        width: '15px',
                                        height: '15px'
                                    }}
                                />
                            </div>
                        </div>
                    }

                    <div onClick={goToAudience} style={{ cursor: authData?.user?.user_id === userProfile?.user_id ? 'pointer' : 'default' }} className='audience'>

                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <FaUsers color={color.dark} size={28} style={{ marginRight: '5px' }} />
                            <h5 style={{ fontWeight: 'bold', color: color.dark }}>{t('network')}</h5>
                            {inviteReceivedCount !== 0 &&
                                <span className='px-2 pt-1 mx-2 bg-danger, text-white font-weigth-500' style={{ backgroundColor: color.danger, borderRadius: '5px' }}>
                                    {inviteReceivedCount}
                                </span>
                            }
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingLeft: '10%', paddingRight: '10%' }}>
                            <h5 style={{ color: color.dark, fontSize: '20px' }}>
                                {userProfile?.friends_accepted_count + userProfile?.friends_invited_count > 1 ?
                                    `${formatCash(userProfile?.friends_accepted_count + userProfile?.friends_invited_count)} ${t('contacts')}`
                                    :
                                    `${formatCash(userProfile?.friends_accepted_count + userProfile?.friends_invited_count)} ${t('contact')}`
                                }
                            </h5>
                            <h5 style={{ color: color.dark, fontSize: '20px' }}>
                                {userProfile?.followers_count > 1 ?
                                    `${formatCash(userProfile?.followers_count)} ${t('followers')}`
                                    :
                                    `${formatCash(userProfile?.followers_count)} ${t('follower')}`
                                }
                            </h5>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', paddingLeft: '10%', paddingRight: '10%' }}>
                            <h5 style={{ color: color.dark, fontSize: '20px' }}>
                                {userProfile?.followings_count > 1 ?
                                    `${formatCash(userProfile?.followings_count)} ${t('following')}`
                                    :
                                    `${formatCash(userProfile?.followings_count)} ${t('following')}`
                                }
                            </h5>
                            <h5 style={{ color: color.dark, fontSize: '20px' }}>
                                {totalUserLikes > 1 ?
                                    `${formatCash(totalUserLikes)} ${t('likes')}`
                                    :
                                    `${formatCash(totalUserLikes)} ${t('like')}`
                                }
                            </h5>
                        </div>

                    </div>

                    <div className='audience'>

                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <MdOutlinePublic color={color.dark} size={28} style={{ marginRight: '5px' }} />
                            <h5 style={{ fontWeight: 'bold', color: color.dark }}>Interactions</h5>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingLeft: '10%', paddingRight: '10%' }}>
                            <h5 style={{ color: color.dark, fontSize: '20px' }}>
                                {userProfile?.opinions_count > 1 ?
                                    `${formatCash(userProfile?.opinions_count)} ${t('posts')}`
                                    :
                                    `${formatCash(userProfile?.opinions_count)} ${t('post')}`
                                }
                            </h5>
                            <h5 style={{ color: color.dark, fontSize: '20px' }}>
                                {userProfile?.votes_count > 1 ?
                                    `${formatCash(userProfile?.votes_count)} Votes`
                                    :
                                    `${formatCash(userProfile?.votes_count)} Vote`
                                }
                            </h5>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', paddingLeft: '10%', paddingRight: '10%' }}>
                            <h5 style={{ color: color.dark, fontSize: '20px' }}>
                                {formatCash(userProfile?.top_comments_count)} Top commen...
                            </h5>
                            <h5 style={{ color: color.dark, fontSize: '20px' }}>
                                {formatCash(userProfile?.top_suggestions_count)} Top sugges...
                            </h5>
                        </div>

                    </div>

                </div>
            </div>
        )
    }


    const renderBuddyButton = () => {
        if (userProfile?.invite_sent?.length === 0 &&
            userProfile?.invite_received?.length === 0 &&
            userProfile?.friends_accepted?.length === 0 &&
            userProfile?.friends_invited?.length === 0) {
            return (
                <button
                    onClick={requestBuddy}
                    className='button'
                    style={{
                        backgroundColor: color.secondary,
                    }}>
                    <MdPersonAddAlt1 size={18} color={color.white} style={{ marginRight: '5px' }} />
                    <span style={{ fontWeight: 'bold', color: color.white, fontSize: '13px', }}>
                        {t('become_buddy')}
                    </span>
                </button>
            )
        }

        if (userProfile?.friends_invited?.length === 0 &&
            userProfile?.friends_accepted?.length === 0 &&
            userProfile?.invite_sent?.length === 0 &&
            userProfile?.invite_received?.length !== 0) {
            return (
                <button
                    onClick={cancelBuddy}
                    className='button'
                    style={{
                        backgroundColor: 'white',
                        fontWeight: '600',
                        border: `1.8px ${color.secondary} solid`
                    }}>
                    <FaUserTimes size={18} color={color.secondary} style={{ marginRight: '5px' }} />
                    <span style={{ fontWeight: 'bold', color: color.secondary, fontSize: '13px', }}>
                        {t('cancel')}
                    </span>
                </button>
            )
        }

        if (userProfile?.friends_accepted?.length !== 0 ||
            userProfile?.friends_invited?.length !== 0) {
            return (
                <button
                    onClick={deleteBuddy}
                    className='button'
                    style={{
                        backgroundColor: 'white',
                        fontWeight: '600',
                        border: `1.8px ${color.secondary} solid`
                    }}>
                    <FaUserTimes size={18} color={color.secondary} style={{ marginRight: '5px' }} />
                    <span style={{ fontWeight: 'bold', color: color.secondary, fontSize: '13px', }}>
                        {t('you_are_buddy')}
                    </span>
                </button>
            )
        }

        if (userProfile?.invite_sent?.length !== 0) {
            return (
                <button
                    onClick={approveBuddy}
                    className='button'
                    style={{
                        backgroundColor: 'white',
                        fontWeight: '600',
                        border: `1.8px ${color.secondary} solid`
                    }}>
                    <MdPersonAddAlt1 size={18} color={color.secondary} style={{ marginRight: '5px' }} />
                    <span style={{ fontWeight: 'bold', color: color.secondary, fontSize: '13px', }}>
                        {t('approve')}
                    </span>
                </button>
            )
        }

    }



    const renderFanButton = () => {
        if (userProfile?.followers?.length !== 0) {
            return (
                <button
                    onClick={becomeOrDeleteFan}
                    className='button'
                    style={{
                        backgroundColor: 'white',
                        fontWeight: '600',
                        border: `1.8px ${color.secondary} solid`
                    }}>
                    <AiOutlineDisconnect size={18} color={color.secondary} style={{ marginRight: '5px' }} />
                    <span style={{ fontWeight: 'bold', color: color.secondary, fontSize: '13px', }}>
                        {t('you_are_fan')}
                    </span>
                </button>
            )
        } else {
            return (
                <button
                    onClick={becomeOrDeleteFan}
                    className='button'
                    style={{
                        backgroundColor: color.secondary,
                    }}>
                    <TbPlugConnected size={18} color={color.white} style={{ marginRight: '5px' }} />
                    <span style={{ fontWeight: 'bold', color: 'white', fontSize: '13px', }}>
                        {t('become_fan')}
                    </span>
                </button>
            )
        }
    }

    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading_opinion')}
                    </h6>
                </div>
            </div>
        )
    }

    return (
        <div className='profile'>

            <div className='header-profile'>
                <button onClick={() => navigate(-1)} className='button-back' >
                    <MdOutlineKeyboardBackspace size={32} />
                </button>
                {userProfile ?
                    <h5 className='title'>
                        {userProfile?.user_surname || ''} {userProfile?.user_name || ''}
                    </h5>
                    :
                    <h5 className='title'>
                        {t('profile')}
                    </h5>
                }
                {authData?.user?.user_id === user_id &&
                    <button onClick={goToSettings}>
                        <TfiMenu size={28} />
                    </button>
                }
            </div>

            <div id='infinite-scroll' className='infinite-scroll'>
                <InfiniteScroll
                    dataLength={opinions.length}
                    next={fetchProfileMoreOpinions}
                    hasMore={hasMore}
                    loader={renderLoader()}
                    refreshFunction={fetchProfile}
                    scrollableTarget='infinite-scroll'
                    height={'92vh'}>
                    {renderProfile()}
                    {opinions.map((item, index) => {
                        return (
                            <div key={index.toString()}>
                                <Opinion
                                    key={index}
                                    userIsMuted={userIsMuted}
                                    opinion={item}
                                    updateOpinion={updateOpinion}
                                    deleteOpinion={deleteOpinion}
                                    sourceFrom='Profile'
                                />
                            </div>
                        )
                    })}
                </InfiniteScroll>
            </div>


            <Audience
                user={userProfile}
                show={showAudience}
                setShow={setShowAudience}
            />

            <ModalEditProfile
                user={userProfile}
                show={showEditProfile}
                setShow={setShowEditProfile}
                refresh={fetchProfile}
            />

            <ModalEditBadges
                user={userProfile}
                show={showEditBadge}
                setShow={setShowEditBadge}
                refresh={fetchProfile}
            />

            <ModalSetting
                user={userProfile}
                likes={totalUserLikes}
                show={showSetting}
                setShow={setShowSetting}
                refresh={fetchProfile}
            />

            <ModelAlert
                message={authData.language === 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
                show={showAlert}
                setShow={setShowAlert}
            />

        </div>
    )
}

export default Profile